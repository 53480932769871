import {
  MouseEvent,
  useRef,
  useState,
  ChangeEvent,
  useEffect,
  useContext,
  useMemo,
  ChangeEventHandler,
  ReactNode,
} from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';
import Box from './Box';
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
import { ReactComponent as FileIcon } from 'icons/file.svg';
import { ReactComponent as DownloadIcon } from 'icons/download.svg';
import { ReactComponent as UploadIcon } from 'icons/upload.svg';
import { ReactComponent as CheckMark } from 'icons/checkmark.svg';
import { ReactComponent as Warning } from 'icons/warning.svg';
import { ReactComponent as Error } from 'icons/error.svg';
import DocumentCategorySelect from 'components/inputs/DocumentCategorySelect';
import http from 'components/Profile/http';
import Spinner from 'components/Spinner';
import { GlobalErrorContext } from 'contexts/GlobalError';
import Popper from 'components/Popper';
import TrashIcon from './TrashIcon';
import classnames from 'classnames';
import { Appointment } from '../../data/useAppointments';
import ReactSelect, { components } from 'react-select';
import useLocalizedDocumentCategories from '../../hooks/useLocalizedDocumentCategories';
interface DocumentProps {
  filename: string;
  filetype: string;
  size?: string;
  uploadDate: Date;
  downloadLink: string;
  deleteCallback?: () => void;
  category: string;
  patientId: number;
  documentId: number;
  refetchDocuments: () => void;
  uploaded_by_shortname?: string;
  uploaded_by_fullname?: string;
  uploaded_by_picture?: any;
  appointments: Appointment[];
  appointment: number | null;
  external_id: string;
}

export default function Document({
  filename,
  filetype,
  size,
  uploadDate,
  downloadLink,
  deleteCallback,
  category = '',
  patientId,
  documentId,
  refetchDocuments,
  uploaded_by_shortname,
  uploaded_by_fullname,
  uploaded_by_picture,
  appointments,
  appointment,
  external_id,
}: DocumentProps) {
  const [selectedCategory, setSelectedCategory] = useState(category);
  const [prescriptionAppointmentId, setPrescriptionAppointmentId] = useState<number | null>(
    appointment,
  );
  const selectWrapperRef = useRef() as any;
  const deleteWrapperRef = useRef() as any;
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(true);
  const { setError } = useContext(GlobalErrorContext);
  const fileCategories = useLocalizedDocumentCategories();

  const handleDelete = (e: MouseEvent) => {
    e.stopPropagation();
    deleteCallback && deleteCallback();
  };

  const handleDownload = (e: MouseEvent) => {
    if (e.target === selectWrapperRef.current || selectWrapperRef.current.contains(e.target))
      return;
    if (e.target === deleteWrapperRef.current || deleteWrapperRef.current.contains(e.target))
      return;
    window.open(downloadLink);
  };

  const isSignedPrescription = useMemo(
    () => selectedCategory === 'signed_prescription',
    [selectedCategory],
  );

  const updateDocumentCategory = async (newCategory: string) => {
    try {
      setLoading(true);
      setFinished(false);
      await http.patch(`/medical/documents/${documentId}/?patient=${patientId}`, {
        content_type: newCategory,
      });
      refetchDocuments();
    } catch (error: any) {
      setError('Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut.');
      setFinished(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectCategory = async (e: ChangeEvent<HTMLSelectElement>) => {
    const category = e.target.value;
    setSelectedCategory(category);
    await updateDocumentCategory(category);
  };

  useEffect(() => {
    let timeout: any;
    if (!loading && !finished) {
      timeout = setTimeout(() => setFinished(true), 2000);
    }
    return () => clearTimeout(timeout);
  }, [finished, setFinished, loading]);

  useEffect(() => {
    setSelectedCategory(category);
  }, [category]);

  const baseButton = 'px-4 py-1 font-radikal rounded-[4px] w-full text-xs';

  return (
    <Box className="cursor-pointer transition-shadow hover:shadow-sm">
      <div className="flex flex-col sm:flex-row justify-between" onClick={handleDownload}>
        <div
          className="flex justify-between py-2 px-4 pb-0 sm:pb-2 cursor-default"
          ref={selectWrapperRef}
        >
          <div className="flex">
            <div className="flex flex-col">
              <DocumentCategorySelect
                external_id={external_id}
                options={fileCategories}
                selected={selectedCategory}
                handleChange={handleSelectCategory}
                name="filetype_select"
              />
              <div className="flex items-center">
                <span className="text-xs text-swopa-secondary-grey">{filename}</span>
                {loading ? (
                  <Spinner className="swopa-grey-2 w-3 h-3 ml-2" />
                ) : (
                  <>{!finished && <CheckMark className="fill-white w-3 h-3 ml-2" />}</>
                )}
              </div>
            </div>
            {size && <span className="text-swopa-secondary-grey text-documents-info">{size}</span>}
          </div>
        </div>
        <div className="flex shrink">
          <div className="flex flex-row justify-center mr-5 items-center">
            <div className="text-swopa-primary-dark-blue text-xs left-0 w-48">{external_id}</div>
            <div
              className={classNames({ invisible: !isSignedPrescription })}
              onClick={(e) => e.stopPropagation()}
            >
              <AppointmentSelect
                external_id={external_id}
                appointments={appointments}
                onChange={setPrescriptionAppointmentId}
                value={prescriptionAppointmentId}
              />
            </div>
          </div>
          <div className="flex items-center">
            <Popper
              triggerOnHover
              className="flex"
              isDoctorCircle
              trigger={
                <span
                  className={classnames(
                    'block overflow-hidden bg-[#16DF9F] border border-white rounded-full mr-2 text-center text-white flex-none',
                    'ml-[-16px] leading-[2.15rem] w-9 h-9  text-xs',
                  )}
                >
                  {uploaded_by_picture && (
                    <img className="rounded-full" src={uploaded_by_picture} alt="" />
                  )}
                  {!uploaded_by_picture && <span>{uploaded_by_shortname}</span>}
                </span>
              }
              content={() => (
                <div className="bg-white p-3 w-max drop-shadow rounded-lg text-center">
                  <span className="text-swopa-primary-dark-blue text-xs">
                    {uploaded_by_fullname}
                  </span>
                </div>
              )}
            />
          </div>
          <div className="flex items-center text-swopa-primary-dark-blue py-2 px-2 text-xs">
            <CalendarIcon className="stroke-swopa-primary-dark-blue mr-2" />
            <span>{format(uploadDate, 'dd.MM.yyyy')}</span>
          </div>
          <div className="flex items-center text-swopa-primary-dark-blue py-2 pl-2 text-xs">
            <FileIcon className="mr-2" />
            <span className="uppercase w-8 flex-none text-ellipsis overflow-hidden">
              {filetype}
            </span>
          </div>
          <div className="flex pl-3 pr-4 py-3 gap-x-1">
            <UploadButton
              appointments={appointments}
              prescriptionAppointmentId={prescriptionAppointmentId}
              visible={isSignedPrescription}
              documentId={documentId}
              external_id={external_id}
              setError={setError}
              refetchDocuments={refetchDocuments}
            />
            <a
              href={downloadLink}
              target="_blank"
              rel="noopener noreferrer"
              download={filename}
              className="text-swopa-primary-dark-blue flex items-center transition-colors hover:text-swopa-secondary-light-blue-hover px-1"
            >
              <DownloadIcon />
            </a>
            <div ref={deleteWrapperRef}>
              <Popper
                trigger={
                  <button className="py-2 px-1 text-swopa-primary-dark-blue">
                    <TrashIcon
                      className="text-swopa-primary-dark-blue"
                      width="18px"
                      height="18px"
                    />
                  </button>
                }
                content={({ setVisible }) => (
                  <div className="bg-swopa-primary-white flex flex-col rounded p-2 drop-shadow">
                    <button
                      onClick={(e: MouseEvent) => {
                        handleDelete(e);
                        setVisible(false);
                      }}
                      className={classNames(
                        baseButton,
                        'bg-swopa-warning-red hover:bg-swopa-warning-red-hover text-white shadow-md',
                      )}
                    >
                      Löschen
                    </button>
                    <button
                      onClick={() => setVisible(false)}
                      className={classNames(
                        baseButton,
                        'mt-2 text-swopa-grey-4 hover:text-swopa-secondary-light-blue',
                      )}
                    >
                      Abbrechen
                    </button>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}

function UploadButton(props: {
  appointments: Appointment[];
  prescriptionAppointmentId: number | null;
  documentId: number;
  external_id: string;
  setError: (message: string) => void;
  refetchDocuments: () => void;
  visible: boolean;
}) {
  type State = {
    buttonContent: ReactNode;
    buttonClass: string;
    helpText: string | undefined;
    disabled: boolean;
  };

  const stateMap = {
    appointmentNotSet: {
      buttonContent: <UploadIcon width="18px" height="18px" />,
      buttonClass: 'opacity-50 cursor-help',
      helpText: 'Vor dem Hochladen der Rezeptur bitte ein Termin auswählen.',
      disabled: true,
    },
    pharmacyNotSet: {
      buttonContent: <UploadIcon width="18px" height="18px" />,
      buttonClass: 'opacity-50 cursor-help',
      helpText: 'Vor dem Hochladen der Rezeptur bitte eine Apotheke auswählen.',
      disabled: true,
    },
    idle: {
      buttonContent: <UploadIcon width="18px" height="18px" />,
      buttonClass: 'hover:text-swopa-secondary-light-blue-hover',
      helpText: undefined,
      disabled: false,
    },
    loading: {
      buttonContent: <Spinner fill="#2D4370" height="18px" width="18px" />,
      buttonClass: 'cursor-wait',
      helpText: 'Rezept in die Apotheke hochladen. Bitte warten Sie.',
      disabled: true,
    },
    success: {
      buttonContent: <CheckMark height="18px" width="18px" />,
      buttonClass: 'cursor-help',
      helpText: 'Rezept hochgeladen.',
      disabled: true,
    },
    generalError: {
      buttonContent: <Error height="18px" width="18px" />,
      buttonClass: 'cursor-help',
      helpText:
        'Rezept kann nicht hochgeladen werden. Versuchen Sie es erneut oder kontaktieren Sie den Administrator..',
      disabled: true,
    },
  };

  const [uploadState, setUploadState] = useState<State>(stateMap.idle);
  const [pharmacyId, setPharmacyId] = useState<number | null>(null);

  useEffect(() => {
    const tempPharmacyId = props.appointments.find(
      (p) => p.id === props.prescriptionAppointmentId,
    )?.pharmacy;

    if (props.external_id) {
      setUploadState(stateMap.success);
      return;
    }

    if (!props.prescriptionAppointmentId) {
      setUploadState(stateMap.appointmentNotSet);
      return;
    }

    if (!tempPharmacyId) {
      setUploadState(stateMap.pharmacyNotSet);
      return;
    }

    setPharmacyId(tempPharmacyId);
    setUploadState(stateMap.idle);
  }, [props.prescriptionAppointmentId, props.external_id]);

  const handleGeneralError = () => {
    setUploadState(stateMap.generalError);
    setTimeout(() => setUploadState(stateMap.idle), 5000);
    props.setError('Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut.');
  };
  const uploadPrescription = async (e: MouseEvent) => {
    e.stopPropagation();

    try {
      setUploadState(stateMap.loading);
      const response = await http.post(`/medical/pharmacies/${pharmacyId}/upload/`, {
        document: props.documentId,
        appointment: props.prescriptionAppointmentId,
      });

      if (response.status === 202) {
        setUploadState(stateMap.success);
      } else {
        handleGeneralError();
      }
    } catch (error: any) {
      handleGeneralError();
      console.error(error);
    } finally {
      props.refetchDocuments();
    }
  };

  return (
    <button
      type="button"
      title={uploadState.helpText}
      className={classNames(
        'text-swopa-primary-dark-blue flex items-center transition-colors px-1',
        uploadState.buttonClass,
        { invisible: !props.visible },
      )}
      onClick={uploadPrescription}
      disabled={uploadState.disabled}
    >
      {uploadState.buttonContent}
    </button>
  );
}

function AppointmentSelect(props: {
  appointments: Appointment[];
  onChange: (value: number | null) => void;
  value: number | null;
  external_id: string;
}) {
  const options = new Array<{ value: number | null; label: string; pznsFilled?: boolean }>({
    value: null,
    label: 'Nicht selektiert',
    pznsFilled: undefined,
  }).concat(
    props.appointments.map((a) => ({
      value: a.id,
      label: a.created_at.substring(0, 10),
      pznsFilled: a.all_pzn_provided,
    })),
  );

  const { Option } = components;
  const AppointmentOption = (props: any) => {
    let statusIcon = null;
    if (props.data.pznsFilled !== undefined) {
      statusIcon = props.data.pznsFilled ? (
        <CheckMark width="14px" height="14px" title="Alle PZN abgeschlossen." />
      ) : (
        <Warning
          width="14px"
          height="14px"
          title="Bitte füllen Sie alle PZNs im Termin aus, um sie auszuwählen."
        />
      );
    }
    return (
      <Option {...props}>
        <span>{props.data.label}</span>
        {statusIcon}
      </Option>
    );
  };

  return (
    <ReactSelect
      unstyled
      components={{ Option: AppointmentOption }}
      isDisabled={!!props.external_id}
      classNames={{
        container: () => 'w-32 !pointer-events-auto',
        control: ({ isDisabled }) => classnames({ 'opacity-50 !cursor-not-allowed': isDisabled }),
        menu: () => 'border border-gray-200 bg-white rounded-lg mt-[-10px] !w-28 overflow-hidden',
        option: ({ isFocused, isDisabled }) =>
          classnames([
            'py-1 px-2 !flex flex-row justify-between items-center',
            { 'bg-gray-500 text-white': isFocused && !isDisabled },
            { 'bg-gray-200 !cursor-not-allowed': isDisabled },
          ]),
      }}
      value={options.find((o) => o.value === props.value)}
      options={options}
      isOptionDisabled={(o) => o.value !== null && !o.pznsFilled}
      className="text-swopa-primary-dark-blue text-xs p-2"
      //@ts-ignore
      onChange={(o) => props.onChange(o.value)}
    />
  );
}
