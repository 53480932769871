import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import Receipt from '../../../types/interfaces/receipt';
import OpenSansRegular from '../../../fonts/OpenSans-Regular.ttf';
import OpenSansBold from '../../../fonts/OpenSans-Bold.ttf';
import { ReceiptPrescription } from '../../../types/receiptPrescription';
import useLocalizedCompanyInformation from '../../../hooks/useLocalizedCompanyInformation';
import useLocalizedImages from '../../../hooks/useLocalizedImages';
import EmptySignature from 'icons/emptySignature.png';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: OpenSansRegular,
      fontWeight: 'normal',
    },
    {
      src: OpenSansBold,
      fontWeight: 'bold',
    },
],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#B2DCEB',
    padding: '1in',
    fontSize: '10pt',
    fontFamily: 'Open Sans',
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontSize: '7pt',
    marginBottom: '5pt',
  },
  logo: {
    width: '100px',
  },
  header: {
    fontSize: '20pt',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  section: {
    marginBottom: '10pt',
  },
  sectionHeader: {
    fontSize: '12pt',
    fontWeight: 'bold',
    marginBottom: '5pt',
  },
  row: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  signature: {
    borderWidth: '1pt',
    borderStyle: 'solid',
    width: '100%',
    backgroundColor: '#fff',
  },
  basis66: {
    flexBasis: '66.33%',
  },
  basis50: {
    flexBasis: '50%',
  },
  basis33: {
    flexBasis: '33.33%',
  },
  field: {
    marginBottom: '2pt',
  },
});

type ReceiptProps = {
  data: Receipt;
};

type PrescriptionPageProps = {
  prescription: ReceiptPrescription;
  logo: string;
};

const Field = (props: { label: string; value: any }) => (
  <View style={styles.field}>
    <Text>
      {props.label}: {props.value}
    </Text>
  </View>
);
export default function ReceiptPdf({
  data: { patient, doctor, prescriptions, signature, pharmacy, isValid, formattedIssueDate },
}: ReceiptProps) {
  // Prevents throwing internal library errors if incomplete data are provided to document
  if (!isValid) {
    return null;
  }

  const { company_name, company_street, company_city, company_phone, company_email } =
    useLocalizedCompanyInformation();
  const { logo } = useLocalizedImages();

  const sortByDate = (p1: ReceiptPrescription, p2: ReceiptPrescription) =>
    new Date(p2.date).getTime() - new Date(p1.date).getTime();

  const PrescriptionPage = (props: PrescriptionPageProps) => (
    <Page size="A4" style={styles.page}>
      <View style={styles.heading}>
        <Image source={logo} style={styles.logo} />
        <View>
          <Text>{company_name}</Text>
          <Text>{company_street}</Text>
          <Text>{company_city}</Text>
          <Text>{company_phone}</Text>
          <Text>{company_email}</Text>
        </View>
      </View>
      <View style={styles.header}>
        <Text>Digitales Rezept</Text>
      </View>
      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text>Patientendaten</Text>
        </View>
        <View style={styles.row}>
        <View style={styles.basis66}>
          <Field label="Geburstdatum" value={patient.date_of_birth} />
            <Field label="Telefonnummer" value={patient.phone_number} />
            <Field label="E-mail" value={patient.email} />
          </View>
          <View style={styles.basis33}>
            <Field label="Nachname" value={patient.last_name} />
            <Field label="Vorname" value={patient.first_name} />
            <Field label="Adresse" value={patient.street} />
            <Field label="Stadt" value={patient.city} />
            <Field label="Postleitzahl" value={patient.postal_code} />
          </View>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.sectionHeader}>
          <Text>Verschreibung</Text>
        </View>
        <View style={styles.row}>
        <View style={styles.basis33}>
          <Text>Rezept Text:</Text>
            <Text>{props.prescription.prescription_text}</Text>
          </View>
          <View style={styles.basis66}>
            <Text>Präparat: {props.prescription.title}</Text>
            <Text>Hersteller: {props.prescription.producer}</Text>
            <Text>Abgabeform: {props.prescription.form}</Text>
            <Text>Menge: {props.prescription.amount}</Text>
            <Text>Einheit: {props.prescription.unit}</Text>
            <Text>Anteil THC: {props.prescription.share_of_thc}</Text>
            <Text>Anteil CBD: {props.prescription.share_of_cbd}</Text>
            <Text>Dauer: {props.prescription.duration}</Text>
          </View>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.row}>
          <View style={styles.basis66}>
            <View style={styles.sectionHeader}>
              <Text>Arztdaten</Text>
            </View>
            <View>
              <Field label="Nachname" value={doctor.last_name} />
              <Field label="Vorname" value={doctor.first_name} />
              <Field label="Adresse" value={doctor.practice?.street} />
              <Field label="Stadt" value={doctor.practice?.city} />
              <Field label="Postleitzahl" value={doctor.practice?.postal_code} />
              <Field label="Telefonnummer" value={doctor.practice?.phone_number} />
              <Field label="E-mail" value={doctor.practice?.email} />
            </View>
          </View>
          <View style={styles.basis33}>
            <View style={styles.sectionHeader}>
              <Text>Apotheke</Text>
            </View>
            <View>
              <Text>{pharmacy}</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.row}>
          <View style={styles.basis33}>
            <View style={styles.sectionHeader}>
              <Text>Unterschrift des Arztes</Text>
            </View>
            <Image src={signature || EmptySignature} style={styles.signature} />
            <Text>Datum: {formattedIssueDate}</Text>
          </View>
        </View>
      </View>
    </Page>
  );

  return (
    <Document>
      {prescriptions.sort(sortByDate).map((p, i) => (
        <PrescriptionPage key={i} prescription={p} logo={logo} />
      ))}
    </Document>
  );
}
