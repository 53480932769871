import { useDropzone, FileWithPath } from 'react-dropzone';
import { ReactComponent as UploadIcon } from 'icons/upload.svg';
import {
  acceptedFileTypesDescription,
  dropzoneAcceptedFileTypes,
} from '../../constants/acceptedFileTypes';

interface DocumentUploadProps {
  onDrop: (files: Array<FileWithPath>) => void;
}

export default function DocumentDropZone({ onDrop }: DocumentUploadProps) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: dropzoneAcceptedFileTypes,
    onDrop,
  });

  return (
    <div
      {...getRootProps()}
      className="border-2 cursor-pointer border-white border-dashed flex flex-col justify-center items-center w-full h-full text-white outline-none"
    >
      <input {...getInputProps()} />
      <UploadIcon className="mb-4 stroke-white" />
      <span className="text-normal text-center">Lassen Sie die Datei los, um sie hochzuladen.</span>
      <span className="text-center">({acceptedFileTypesDescription})</span>
    </div>
  );
}
