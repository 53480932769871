import { groupBy } from 'lodash';
import { startOfDay, parseISO, format, isAfter } from 'date-fns';

import { Document } from 'data/useDocuments';
import { getDocumentCategoryName } from 'utils/getDocumentCategoryName';

export default function DocumentsList({ documents }: { documents: Array<Document> }) {
  const groupedFeed = groupBy(documents, (item: Document) =>
    startOfDay(parseISO(item.uploaded_at)),
  );
  const sortedFeed = Object.entries(groupedFeed).sort((a: any, b: any) =>
    isAfter(new Date(a[0]), new Date(b[0])) ? -1 : 1,
  );

  if (!documents?.length) {
    return (
      <div className="px-4 pb-4 mb-4">
        <span className="text-swopa-grey-4">Keine dokumente</span>
      </div>
    );
  }

  return (
    <div className="pb-3">
      {sortedFeed.map(([key, items]) => {
        const date = format(new Date(key), 'dd.MM.yyyy');
        return (
          <div
            key={key}
            className="border-b mx-4 pb-2 mb-2 last-of-type:border-0 last-of-type:pb-0 last-of-type:mb-0"
          >
            <span className="text-swopa-grey-4 block mb-1">{date}</span>
            <ul>
              {items.map((item) => (
                <li key={item.id} className="text-sm text-swopa-primary-dark-blue mb-1">
                  <a
                    href={item.asset}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-swopa-secondary-light-blue-hover truncate text-ellipsis block"
                  >
                    {`${getDocumentCategoryName(item.content_type)} ${item.content_type === '' ? item.name : '(' + item.name + ')'}`}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
}
