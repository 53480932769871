import React, { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { Patient } from '../data/usePatients';
import { Prescription } from '../data/usePrescriptions';
import { Doctor } from '../types/doctor';
import { ReceiptPrescription } from '../types/receiptPrescription';
import http from '../components/Profile/http';
import Receipt from '../types/interfaces/receipt';

interface ReceiptState {
  prescriptions: ReceiptPrescription[];
  signature: string;
  pharmacy: string;
}

const convert = (prescription: Prescription): ReceiptPrescription => ({
  id: prescription.id,
  date: prescription.date,
  title: prescription.pharmaceutical_free_text
    ? prescription.pharmaceutical_free_text
    : prescription.title,
  amount: prescription.amount,
  unit: prescription.unit,
  share_of_thc: prescription.share_of_thc,
  share_of_cbd: prescription.share_of_cbd,
  form: prescription.form,
  duration: prescription.duration,
  prescription_text: prescription.prescription_text,
  producer: prescription.producer,
});

type ReceiptForm = {
  patient: Patient;
  doctor: Doctor;
  prescriptions: ReceiptPrescription[];
  signature: string;
  pharmacy: string;
  onSignatureUpdate: (signature: string) => void;
  onPharmacyUpdate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isValid: boolean;
  fileName: string;
  formattedIssueDate: string;
  pharmacySelected: boolean;
};

export function useReceipt(
  prescriptions: Prescription[],
  patient: Patient,
  doctor: Doctor,
  pharmacyId: number | null,
): ReceiptForm {
  const [receipt, setReceipt] = useState<ReceiptState>({
    prescriptions: prescriptions.map(convert),
    signature: '',
    pharmacy: '',
  });

  useEffect(() => {
    if (pharmacyId) {
      http
        .get(`medical/pharmacies/${pharmacyId}/`)
        .then((res) => setReceipt({ ...receipt, pharmacy: res.name }))
        .catch((err) => console.error(err));
    }
  }, [pharmacyId]);

  useEffect(() => {
    setReceipt((prevState) => ({
      ...prevState,
      prescriptions: prescriptions.map(convert),
    }));
  }, [prescriptions]);

  const onSignatureUpdate = (signature: string) => {
    setReceipt((prevState) => ({
      ...prevState,
      signature: signature,
    }));
  };

  const onPharmacyUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReceipt((prevState) => ({
      ...prevState,
      pharmacy: e.target.value,
    }));
  };

  const isValid = useMemo(
    () => !!pharmacyId || receipt.pharmacy.length > 0,
    [receipt.pharmacy, pharmacyId],
  );

  const fileName = useMemo(
    () => `${format(new Date(), 'yyyy_MM_dd')}_${patient.last_name}.pdf`,
    [patient],
  );

  const formattedIssueDate = useMemo(() => format(new Date(), 'dd.MM.yyyy'), []);

  const pharmacySelected = useMemo(() => !!pharmacyId, [pharmacyId]);

  return {
    patient,
    doctor,
    ...receipt,
    onSignatureUpdate,
    onPharmacyUpdate,
    isValid,
    fileName,
    formattedIssueDate,
    pharmacySelected,
  };
}
