import { useFormik } from 'formik';
import Input from 'components/inputs/Input';
import { patch } from 'data/http';
import useUser from 'data/useUser';
import { useContext, useState } from 'react';
import UploadFileButton from 'components/Documents/UploadFileButton';
import { FileWithPath } from 'react-dropzone';
import { readFileAsync } from 'utils/files';
import Button from 'components/Button';
import { GlobalErrorContext } from 'contexts/GlobalError';
import { acceptedFileTypes } from '../../constants/acceptedFileTypes';

type BasicInformationFormValues = {
  occupational_title: string;
  first_name: string;
  last_name: string;
  gender: string;
};

export default function BasicInformationForm() {
  const { setSuccess, setError } = useContext(GlobalErrorContext);
  const {
    user,
    user: { managed_doctor, assistant },
    mutate,
  } = useUser();
  const [file, setFile] = useState<string | null>();
  const isDoctor = managed_doctor && managed_doctor.id;
  const initialValues: BasicInformationFormValues = {
    occupational_title: isDoctor
      ? managed_doctor.occupational_title
      : assistant?.occupational_title,
    first_name: isDoctor ? managed_doctor.first_name : assistant?.first_name,
    last_name: isDoctor ? managed_doctor.last_name : assistant?.last_name,
    gender: isDoctor ? managed_doctor.gender : assistant?.gender,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {},
  });

  const { values, handleChange, handleSubmit, handleBlur, touched, errors } = formik;

  const onSubmit = () => {
    const data: any = values;
    if (file) {
      data.profile_picture = file;
    }
    patch('auth/users/me/', data)
      .then(() => {
        setFile(null);
        mutate();
        setSuccess('Ihre persönlichen Daten wurden erfolgreich aktualisiert');
      })
      .catch((err) => {
        setError('Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut.');
      });
  };

  const handleDocumentUpload = async (files: Array<FileWithPath>) => {
    for (let i = 0; i < files.length; ++i) {
      const filesize = (files[i].size / 1024 / 1024).toFixed(4);
      if (Number(filesize) > 20) {
      }
      if (!acceptedFileTypes.find((type) => type === files[i].type)) {
      }
    }

    const assets = await Promise.all(files.map((file) => readFileAsync(file)));

    const forUpload = files.map(({ name }, index) => ({
      filename: name,
      asset: assets[index] + '',
    }));

    const { asset } = forUpload[0];
    setFile(asset);
  };

  const renderProfileImage = () => {
    if (file) {
      return <img className="rounded-full" src={file} alt="" />;
    }

    if (user.profile_picture) {
      return <img className="rounded-full" src={user.profile_picture} alt="" />;
    }

    return <span>{isDoctor ? managed_doctor.short_name : assistant.short_name}</span>;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="max-w-sm mb-6">
        <div className="flex items-center">
          <span className="block overflow-hidden bg-[#16DF9F] border border-white rounded-full text-center text-white flex-none leading-[4rem] w-16 h-16 text-sm">
            {renderProfileImage()}
          </span>
          <div className="ml-4">
            <UploadFileButton noIcon={true} onOpen={() => null} onUpload={handleDocumentUpload}>
              <a
                href=""
                onClick={(e) => e.preventDefault()}
                className="text-swopa-secondary-light-blue"
              >
                Profilbild hochladen
              </a>
            </UploadFileButton>
          </div>
        </div>
        {file && (
          <div className="flex my-2 max-w-sm ">
            <div
              className="bg-orange-100 w-full border-l-4 border-orange-500 text-orange-700 p-4"
              role="alert"
            >
              <p>Sie müssen immer noch auf Speichern klicken, um das Bild zu aktualisieren</p>
            </div>
          </div>
        )}
      </div>

      <div className="flex mb-6">
        <div className="flex items-center mr-4">
          <input
            checked={values.gender === 'm'}
            onChange={handleChange}
            id="inline-radio"
            type="radio"
            value="m"
            name="gender"
            className="outline-0"
          />
          <label htmlFor="inline-radio" className="ml-2 text-sm text-swopa-primary-dark-blue">
            Herr
          </label>
        </div>
        <div className="flex items-center mr-4">
          <input
            checked={values.gender === 'w'}
            onChange={handleChange}
            id="inline-2-radio"
            type="radio"
            value="w"
            name="gender"
            className="outline-0"
          />
          <label htmlFor="inline-2-radio" className="ml-2 text-sm text-swopa-primary-dark-blue">
            Frau
          </label>
        </div>
        <div className="flex items-center mr-4">
          <input
            checked={values.gender === 'd'}
            onChange={handleChange}
            id="inline-checked-radio"
            type="radio"
            value="d"
            name="gender"
            className="outline-0"
          />
          <label
            htmlFor="inline-checked-radio"
            className="ml-2 text-sm text-swopa-primary-dark-blue"
          >
            Divers
          </label>
        </div>
      </div>

      <div className="max-w-sm mb-6">
        <label className="text-swopa-secondary-light-blue uppercase text-pre-title font-radikal">
          Titel
        </label>
        <Input
          onChange={handleChange}
          value={values.occupational_title}
          type="text"
          id="occupational_title"
          name="occupational_title"
          onBlur={handleBlur}
          autoComplete="occupational_title"
          placeholder="Titel"
          touched={touched['occupational_title']}
          error={errors['occupational_title']}
          errorDots
          bgWhite
        />
      </div>
      <div className="max-w-sm mb-6">
        <label className="text-swopa-secondary-light-blue uppercase text-pre-title font-radikal">
          Vorname
        </label>
        <Input
          onChange={handleChange}
          value={values.first_name}
          type="text"
          id="first_name"
          name="first_name"
          onBlur={handleBlur}
          autoComplete="first_name"
          placeholder="Vorname"
          touched={touched['first_name']}
          error={errors['first_name']}
          bgWhite
          errorDots
        />
      </div>
      <div className="max-w-sm mb-6">
        <label className="text-swopa-secondary-light-blue uppercase text-pre-title font-radikal">
          Nachname
        </label>
        <Input
          onChange={handleChange}
          value={values.last_name}
          type="text"
          id="last_name"
          name="last_name"
          onBlur={handleBlur}
          autoComplete="last_name"
          placeholder="Nachname"
          touched={touched['last_name']}
          error={errors['last_name']}
          bgWhite
          errorDots
        />
      </div>
      <Button onClick={onSubmit} className="inline-block w-auto">
        Speichern
      </Button>
    </form>
  );
}
