export const dropzoneAcceptedFileTypes = {
  'application/msword': ['.doc', 'dot'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'image/jpeg': ['.jfif', '.jpeg', '.pjp', '.jpg', '.pjpeg'],
  'application/pdf': ['.pdf'],
  'image/png': ['.png'],
  'text/plain': ['.text', '.txt'],
  'image/webp': ['.webp'],
};

export const acceptedFileTypes = Object.keys(dropzoneAcceptedFileTypes);
export const inputAcceptedFileTypes = acceptedFileTypes.join(', ');

export const acceptedFileTypesDescription =
  'DOT, DOC, DOCX, JFIF, JPEG, PJP, JPG, PJPEG, PDF, PNG, TEXT, TXT, WEBP | max. 20MB/Datei';
